<template>
    <div class="home">
        <van-nav-bar class="van-nav-bar--fixed" title="幽默猜测" left-arrow  right-text="开奖记录"  @click-left="onClickLeft"  @click-right="onClickRight">
        </van-nav-bar>
       
        <div class="m-scene-vote">
            <div class="customfixed">
                <div class="sticky-body">
                    
                    <div class="m-tabs-years">
                            <div class="m-tabs-years-bd">
                                <span v-for="item in lotto_list" :key="item.id" :class="lotto_id==item.id ? 'act':''" @click="setLotto(item.id)">{{ item.name }}</span>
                            </div>
                     </div>

                    <div class="m-tabs-years">
                            <div class="m-tabs-years-bd">
                                <span v-for="item in year_list" :key="item.id" :class="year_id==item.id ? 'act':''" @click="setYear(item.id)">{{ item.name }}年</span>
                            </div>
                     </div>
 
                    <div class="m-tabs-period-home van-row">
                        <div class="m-tabs-period">
                            <div class="m-tabs-period-bd">
                                <span v-for="item in journal_list" :key="item.value" :class="journal_id==item.value ? 'act':''" @click="setJournal(item.value)">{{ item.text }}</span>
                            </div>
                        </div>
                        <van-dropdown-menu active-color="#1989fa">
                            <van-dropdown-item v-model="journal_id" :options="option1" @change="getData()" />
                        </van-dropdown-menu>
                    </div>
                </div>
            </div>
         
            <KaiJiang :lotto_id="lotto_id" :year_id="year_id" :journal_id="journal_id" />
            <div class="vote-content" v-if="data.id>0">
                <div class="title">{{ data.title }}</div>
                <div class="text">
                    <p>{{ data.content }}</p>
                </div>
            </div>
            <div class="imgPos"
                style="touch-action: pan-y; user-select: none; -webkit-user-drag: none; -webkit-tap-highlight-color: rgba(0, 0, 0, 0);" v-if="data.id>0">
                <div class="ms-bd-img" v-for="(image,index) in data.smallimages" :key="index"><img :src="siteurl+image" @click="onPreview(siteurl+image)"></div>
            </div>
           
        </div>
    </div>
</template>

<script>
import { Toast } from 'vant'
import { ImagePreview } from 'vant';
import KaiJiang from '@/components/KaiJiang.vue'

export default {
    components: {
    KaiJiang
  },
  data() {
    return {
      year_id:1,
      lotto_id:2,
      journal_id:1,
      active: 'vote',
      list:[],
      data:[],
      lotto_list:[],
      journal_list:[],
      year_list:[],
      loading: false,
      finished: false,
      iserror:false,
      page:1,
      message:'',
      iscolour: 0,
      option1: [],
    };
  },
  mounted() {
        this.lotto_id= parseInt(this.$route.query.lotto_id) ? parseInt(this.$route.query.lotto_id)  : 2;
        this.journal_id= parseInt(this.$route.query.id) ? parseInt(this.$route.query.id)  : 1;
        //this.active='picture';
       this.getData();
    },
    methods: {
        onPreview(url){
            ImagePreview({
            images: [url],
            closeable: true,
            });
        },
        onClickLeft() {
            // 返回上一级页面
            this.$router.back();
        },
        onClickRight() {
            this.$router.push({ path: '/kaijiang?lotto_id='+this.lotto_id});
        },
        setLotto(id){
            this.lotto_id=id;
            this.getData();
        },
        setYear(id){
            this.year_id=id;
            this.getData();
        },
        setJournal(id){
            this.journal_id=id;
            this.getData();
        },
        getData() {
            Toast.loading({
            message: '加载中...',
            forbidClick: true,
            });
            this.$http
                .get("api/vote/show?year_id="+this.year_id+"&lotto_id="+this.lotto_id+"&journal_id="+this.journal_id)
                .then(function (response) {
                   
                    if (response.data.code == "ok") {
                        if(!response.data.data.data.id){
                            this.data= [];
                        }else{
                            this.data= response.data.data.data;
                        }
                       
                        this.lotto_list= response.data.data.lotto_list;
                        
                        this.year_list= response.data.data.year_list;
                    
                        this.journal_list= response.data.data.journal_new;
                  
                        this.option1=response.data.data.journal_new;

                        if(this.journal_id<2){
                            this.journal_id=response.data.data.journal_id;
                        }

                        this.loading = false;
                    } else {
                        this.iserror = true;
                        this.message = response.data.msg;
                    }
                    Toast.clear();
                    this.loading = false;
                   // console.log(this.option1);
                });
    
        },

    },
};
</script>


<style>
.vote-content {
    padding-top: 30px;
    padding-bottom: 30px;
}
.vote-content div.title{
    font-size: 20px;
    text-align: center;
    color: #000;
   line-height: 36px;
   font-weight: bold;
}
.vote-content div.text{
    line-height: 28px;
}
.m-scene-vote {
    padding-top: 0px;
}
div#app {
    background-color: #fff;
}
.m-scene-vote .customfixed {
    position: initial;
}
    .van-index-anchor {
    color: #07c160;
    font-size: .32rem;
}
.number {
    position: absolute;
    right: 0.1rem;
    color: #07c160;
    padding: 0 0.3rem;
    background: #f2f2f2;
    border-radius: 0.04rem;
    font-size: .24rem;
}
.background-color{background-color: #ffffff;}
</style>